









import { ModalFormAccountNameUpdateTs } from './ModalFormAccountNameUpdateTs';
export default class ModalFormAccountNameUpdate extends ModalFormAccountNameUpdateTs {}
