















import { AccountMetadataDisplayTs } from './AccountMetadataDisplayTs';
export default class AccountMetadataDisplay extends AccountMetadataDisplayTs {}
