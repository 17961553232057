














































import { FormAccountNameUpdateTs } from './FormAccountNameUpdateTs';
export default class FormAccountNameUpdate extends FormAccountNameUpdateTs {}
