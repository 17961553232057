









































import { AccountMultisigGraphTs } from './AccountMultisigGraphTs';

export default class AccountLinks extends AccountMultisigGraphTs {}
