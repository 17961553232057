











import { AccountAliasDisplayTs } from './AccountAliasDisplayTs';
export default class AccountAliasDisplay extends AccountAliasDisplayTs {}
