









import { AccountPublicKeyDisplayTs } from './AccountPublicKeyDisplayTs';
export default class AccountPublicKeyDisplay extends AccountPublicKeyDisplayTs {}
