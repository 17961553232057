








import { AccountAddressDisplayTs } from './AccountAddressDisplayTs';
export default class AccountAddressDisplay extends AccountAddressDisplayTs {}
