







import { ImportanceScoreDisplayTs } from './ImportanceScoreDisplayTs';

export default class ImportanceScoreDisplay extends ImportanceScoreDisplayTs {}
