
















import { AccountNameDisplayTs } from './AccountNameDisplayTs';

export default class AccountNameDisplay extends AccountNameDisplayTs {}
